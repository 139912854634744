import React from 'react';
import ReactDOM from 'react-dom/client';
import Saidbar from './components/saidbar'; // Assuming your component file is named Saidbar
import Connect from './components/connect';
import Deshbord from './components/deshbord';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {
  ThirdwebProvider,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
} from "@thirdweb-dev/react";

import { ChainId } from "@thirdweb-dev/sdk";

const chainId = ChainId.BinanceSmartChainMainnet;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter >
    <ThirdwebProvider
      supportedWallets={[metamaskWallet(), coinbaseWallet(), walletConnect()]}
      activeChain={56}
      clientId="f2cc608e5b79ef627b45bdf54020846b"
    >
        <App />
      </ThirdwebProvider>
    </BrowserRouter>
  </React.StrictMode>
);

 